import * as __SNOWPACK_ENV__ from '../../_snowpack/env.js';

const TRACKING_SCRIPT_ID = 'nucleus-script'
const DATA_PUBLIC_KEY = 'npuk'
const DATA_APP_TYPE = 'nat'
const DATA_CONSENT = 'consent'
const DATA_CONTENT_CONTAINER_IDENTIFIER = 'content-container'
const DATA_SPA_SITE = 'spa'
const DATA_CUSTOM_USER_ID = 'custom-user-id'
const DATA_EXTRAS = 'extras'

export const getMeta = () => {
	const meta = {
		widget: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_WIDGETS_WIDGETS,
		version: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_WIDGETS_VERSION_WIDGETS,
		current_url: window?.location?.href
	}

	return meta
}

export const getConsentConfig = () => {
	const script = document.querySelector(`#${TRACKING_SCRIPT_ID}`)
	const consentConfig = script.getAttribute(`data-${DATA_CONSENT}`) || null
	const shouldConsent = +consentConfig === 1
	return shouldConsent
}

export const getConfig = () => {
	const script = document.querySelector(`#${TRACKING_SCRIPT_ID}`)
	const publicKey = script.getAttribute(`data-${DATA_PUBLIC_KEY}`)
	const appType = script.getAttribute(`data-${DATA_APP_TYPE}`)
	const contentContainerIdentifier = script.getAttribute(`data-${DATA_CONTENT_CONTAINER_IDENTIFIER}`)
	const customUserId = script.getAttribute(`data-${DATA_CUSTOM_USER_ID}`)
	let extras = script.getAttribute(`data-${DATA_EXTRAS}`)
	
	try {
		extras = JSON.parse(extras)
	} catch (error) {
		console.error('Could not parse extras. Keeping string for now...')
	}

	return {
			publicKey,
			appType,
			contentContainerIdentifier,
			customUserId,
			extras
	}
}

export const getIsSpa = () => {
	const script = document.querySelector(`#${TRACKING_SCRIPT_ID}`)
	const spaConfig = script.getAttribute(`data-${DATA_SPA_SITE}`) || null
	const isSpa = +spaConfig === 1
	return isSpa
	
}