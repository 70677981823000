export const getStyling = (background, color = "#fff") => `background: ${background}; color: ${color}; padding: 1px; border-radius: 5px;`;
export const debugConsole = (message, identifier = null, type) => {
  if (!localStorage.getItem("wordpay-debug")) {
    return;
  }
  const consoleType = type || "";
  switch (consoleType) {
    case "info":
      console.info(`${`%c[${identifier}]: `}`, getStyling("blue"), message);
      break;
    case "error":
      console.error(`${`%c[${identifier}]: `}`, getStyling("red"), message);
      break;
    case "warn":
      console.warn(`${`%c[${identifier}]: `}`, getStyling("yellow", "#1e1e1e"), message);
      break;
    default:
      console.warn(`${`%c[${identifier}]: `}`, getStyling("green"), message);
      break;
  }
};
