export const getCPUs = () => (
	// DP06: Get CPU details (can be 1, 2, 8, 16, 32, 64)
	window?.navigator?.hardwareConcurrency || null
)

export const getDisplayDetails = () => {
	const resolution = window?.screen?.width + "x" + window?.screen?.height // -> Virtual screen resolution (not physical pixels)
	const dpr = window?.devicePixelRatio
	const dpi = (pts => {
		while (pts--) {
			if (window?.matchMedia("(min-resolution: " + pts + "dpi)").matches) return pts
			if (pts < -1) return null // TODO: Test Error handling
		}
	})(802/* -> Max as of 2022: Sony Xperia Z5 Premium at 801dpi */)
	
	return {
		resolution,
		dpr,
		dpi,
		consolidated: `${resolution}-${dpr}-${dpi}`
	}
}

export const getGPU = () => {
	const wgl = document.createElement("canvas").getContext("webgl")
	const gpu = wgl.getParameter(wgl.getExtension("WEBGL_debug_renderer_info")?.UNMASKED_RENDERER_WEBGL) || /*FF*/wgl.getParameter(wgl.RENDERER)
	return gpu
}