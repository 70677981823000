import * as __SNOWPACK_ENV__ from '../../_snowpack/env.js';

export const STORAGE_SESSION_KEY = 'wordpay-session-key'
export const STORAGE_VISITOR_KEY = 'wordpay-visitor-key'

// DEV - 10 sec
// PROD - 30 min OR 60 min
export const SESSION_TTL = 'local' === __SNOWPACK_ENV__.SNOWPACK_PUBLIC_ENVIRONMENT ? (10 * 1000) : (30 * 60 * 1000)
export const VISITOR_TTL = 'local' === __SNOWPACK_ENV__.SNOWPACK_PUBLIC_ENVIRONMENT ? (10 * 1000) : (60 * 60 * 1000)
 
// DEV - 10 sec
// PROD - 2 year
export const STORAGE_CONSENT_KEY = 'nucleus-consent'
export const STORAGE_CONSENT_TTL = 'local' === __SNOWPACK_ENV__.SNOWPACK_PUBLIC_ENVIRONMENT ? (10 * 1000) : (2 * 365 * 60 * 1000)

export function getStorage(storageKey) {
	const now = new Date()
	
	try {
			const storage = localStorage.getItem(storageKey) ? JSON.parse(localStorage.getItem(storageKey)) : null

			if (storage?.ttl < now.getTime()) {
					localStorage.removeItem(storageKey)
					return null
			}

			return storage?.value
	} catch (error) {
			console.error('Could not parse')
			return null
	}
}

export function setStorage(storageKey, payload, ttl = SESSION_TTL) {
	const now = new Date()
	let isStored = false
	
	try {
			'object' == typeof payload && localStorage.setItem(storageKey, JSON.stringify({ value: payload, ttl: now.getTime() + ttl }))
			isStored = true
	} catch (error) {
			console.error('Could not parse and store content')
	} finally {
			return isStored
	}
}

export function removeFromStorage(storageKey) {
	try {
		localStorage.removeItem(storageKey)
	} catch (error) {
		console.error(`Could not remove ${storageKey} from storage`)
	}
}