import { v4 as uuidV4 } from '../../_snowpack/pkg/uuid.js'
import { STORAGE_SESSION_KEY, SESSION_TTL, setStorage } from '../util/Storage.js'

export const generateVisitorId = () => {
	let visitId = null
	try {
		visitId = crypto.randomUUID()
	} catch (error) {
		console.warn('crypto.randomUUID not supported')
		visitId = uuidV4()
	} finally {
		setStorage(STORAGE_SESSION_KEY, { visitId }, SESSION_TTL)
		return visitId
	}
}