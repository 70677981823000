import {debugConsole} from "../util/debugConsole.js";
import {
  STORAGE_CONSENT_KEY,
  STORAGE_CONSENT_TTL,
  setStorage,
  getStorage,
  removeFromStorage
} from "../util/Storage.js";
const EVENT_CONSENT = "nucleus-consent";
const onConsent = (has_consent) => {
  const consentEvent = new CustomEvent(EVENT_CONSENT, {detail: {has_consent}});
  window.dispatchEvent(consentEvent);
};
export const setConsentListener = (callback) => {
  window.addEventListener(EVENT_CONSENT, ({detail}) => {
    const {has_consent} = detail;
    window.nucleus.has_consent = has_consent;
    callback && callback(has_consent);
    const now = new Date();
    has_consent ? setStorage(STORAGE_CONSENT_KEY, {has_consent, consent_given: now.getTime()}, STORAGE_CONSENT_TTL) : removeFromStorage(STORAGE_CONSENT_KEY);
    debugConsole(`Consent set ${has_consent ? "true" : "false"}`, "NUCLEUS_CONSENT", "info");
  });
};
export const setupTrigger = () => {
  const consentStorage = getStorage(STORAGE_CONSENT_KEY);
  window.nucleus = {
    has_consent: consentStorage?.has_consent || false,
    consent_given: () => {
      onConsent(true);
    },
    consent_withdraw: () => {
      onConsent(false);
    }
  };
  debugConsole(`Current consent: ${window.nucleus?.has_consent ? "true" : "false"}`, "NUCLEUS_CONSENT", "info");
};
export const checkConsent = () => window.nucleus?.has_consent;
export const setConsent = (has_consent) => onConsent(has_consent);
export const consentGiven = () => {
  setConsent();
};
