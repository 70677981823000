import { getConfig } from '../util/config.js'

let pageMaxScrollDepth = 0
let contentMaxScrollDepth = 0

const { contentContainerIdentifier } = getConfig()

// Define different targets
const getContentContainer = () => {
	const identifiers = ['article', 'main', '.article', '.content']
	let contentContainer = document.querySelector(`${contentContainerIdentifier}`)

	if (contentContainer) {
		return contentContainer
	}

	identifiers.forEach(identifier => {
		if (document.querySelector(identifier)) {
			contentContainer = document.querySelector(identifier)
			return
		}
	})

	return contentContainer
}

const contentContainer = getContentContainer()

const setPageMaxScroll = (currentScrollDepth) => {
	// Assign max scroll depth to variable
	if (pageMaxScrollDepth < currentScrollDepth) {
		pageMaxScrollDepth = currentScrollDepth
	}
}

const setContentMaxScroll = (currentScrollDepth) => {
	// Assign max scroll depth to variable
	if (contentMaxScrollDepth < currentScrollDepth) {
		contentMaxScrollDepth = currentScrollDepth
	}
}

export const getPageMaxScroll = () => pageMaxScrollDepth

export const getContentMaxScroll = () => contentMaxScrollDepth

export const getPageScrollDepth = () => {
	const scrollY = Math.round(window.scrollY)
	const scrollHeight = document.documentElement.scrollHeight
	const innerHeight = window.innerHeight

	const pageScrollDepth = Math.round(scrollY / (scrollHeight - innerHeight) * 100)

	// Assign max scroll depth to variable
	setPageMaxScroll(pageScrollDepth)

	return pageScrollDepth
}

export const getContentScrollDepth = () => {
	const scrollY = Math.round(window.scrollY)
	const innerHeight = window.innerHeight

	const contentOffsetTop = contentContainer?.offsetTop || 0
	const contentScrollY = contentContainer?.scrollHeight || 0
	const contentContainerScrollDepthRaw = Math.round(((scrollY - contentOffsetTop) / (contentScrollY - innerHeight)) * 100)
	let contentContainerScrollDepth = Math.abs(contentContainerScrollDepthRaw) || 0 // Convert potential negative number to positive

	if (contentContainerScrollDepthRaw < 0) {
		contentContainerScrollDepth = 0
	} else if (contentContainerScrollDepthRaw > 100) {
		contentContainerScrollDepth = 100
	}

	// Assign max scroll depth to variable
	setContentMaxScroll(contentContainerScrollDepth)

	return contentContainerScrollDepth
}

export const getScrollDepth = () => {
	const pageScrollDepth = getPageScrollDepth()
	const contentScrollDepth = getContentScrollDepth()
	const pageScrollDepthMax = getPageMaxScroll()
	const contentScrollDepthMax = getContentMaxScroll()

	return {
		pageScrollDepth,
		contentScrollDepth,
		pageScrollDepthMax,
		contentScrollDepthMax
	}
}