import {sendBeacon} from "./beacon.js";
import {ENDPOINT_WIDGET_VISIT} from "../util/api.const.js";
import {getMeta, getConfig, getConsentConfig} from "../util/config.js";
const CONSENT = {
  NOT_DECLARED: 0,
  NOT_GIVEN: -1,
  GIVEN: 1
};
export const sendVisit = (visit) => {
  const meta = getMeta();
  const {publicKey, appType, customUserId, extras} = getConfig();
  const needs_consent = getConsentConfig();
  const has_consent = window.nucleus.has_consent || null;
  let consent_status = CONSENT.NOT_DECLARED;
  if (needs_consent) {
    consent_status = has_consent ? CONSENT.GIVEN : CONSENT.NOT_GIVEN;
  }
  const body = {
    ...visit,
    widget_name: meta.widget,
    widget_version: meta.version,
    app_type: appType,
    public_key: publicKey,
    custom_user_id: customUserId ? customUserId : null,
    extras: JSON.stringify(extras) || null,
    needs_consent,
    consent_status
  };
  sendBeacon({url: ENDPOINT_WIDGET_VISIT, body});
};
