import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';

import {debugConsole} from "./util/debugConsole.js";
import {setConsentListener, setupTrigger} from "./triggers/Trigger.consent.js";
(async () => {
  debugConsole("Initializing nucleus-script", "NUCLEUS_INIT", "info");
  try {
    attachScript();
    setupTrigger();
    setConsentListener();
  } catch (error) {
    debugConsole(`Could not attach script. ${error}`, "NUCLEUS_INIT", "info");
  }
})();
async function attachScript() {
  debugConsole(`Should attach script`, "NUCLEUS_INIT", "info");
  const meta = {
    widget: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_WIDGETS_WIDGETS,
    version: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_WIDGETS_VERSION_WIDGETS,
    current_url: window?.location?.href
  };
  debugConsole(`Current version: ${meta?.widget} ${meta?.version}`, "NUCLEUS", "info");
  const tracking = await import("./apps/Tracking.js");
  await tracking.init();
}
