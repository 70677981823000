import React, {useEffect} from "../../_snowpack/pkg/react.js";
import {createRoot} from "../../_snowpack/pkg/react-dom/client.js";
import "../../_snowpack/pkg/url-change-event.js";
import {Fingerprint} from "../components/Fingerprint/index.js";
import {
  getTimeZone,
  generateVisitorId,
  getFullUrl,
  getReferrer,
  getScrollDepth,
  getCPUs,
  getDisplayDetails,
  getGPU
} from "../metrics/index.js";
import {sendVisit} from "../service/Service.visit.js";
import {
  setStorage,
  getStorage,
  SESSION_TTL,
  VISITOR_TTL,
  STORAGE_SESSION_KEY,
  STORAGE_VISITOR_KEY
} from "../util/Storage.js";
import {getTimeStamps} from "../util/timestamps.js";
import {getIsSpa} from "../util/config.js";
import {debugConsole} from "../util/debugConsole.js";
const target = "nucleus";
const Tracking = ({visitorId}) => {
  const isSpa = getIsSpa();
  const fingerprint = visitorId;
  const timeZone = getTimeZone();
  const fullUrl = getFullUrl();
  const referrer = getReferrer();
  const cpuCount = getCPUs();
  const displayDetails = getDisplayDetails();
  const gpu = getGPU();
  let startTime = getTimeStamps();
  let visitId = getStorage(STORAGE_SESSION_KEY)?.visitId || null;
  let currentUrl = location.href;
  if (!visitId) {
    visitId = generateVisitorId();
  } else {
    setStorage(STORAGE_SESSION_KEY, {visitId}, SESSION_TTL);
  }
  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  const constructVisit = (url = null) => {
    const {timestampUnix, timestampUtc} = getTimeStamps();
    const {
      pageScrollDepth,
      contentScrollDepth,
      pageScrollDepthMax,
      contentScrollDepthMax
    } = getScrollDepth();
    const display_resolution = displayDetails?.resolution || "";
    const display_dpr = displayDetails?.dpr || "";
    const display_dpi = displayDetails?.dpi || "";
    return {
      start_time: startTime.timestampUtc,
      start_time_unix: startTime.timestampUnix,
      url: url || fullUrl,
      time_zone: timeZone,
      fingerprint,
      visit_id: visitId,
      end_time: timestampUtc,
      end_time_unix: timestampUnix,
      source: referrer,
      page_scroll_depth: pageScrollDepth,
      page_scroll_depth_max: pageScrollDepthMax,
      content_scroll_depth: contentScrollDepth,
      content_scroll_depth_max: contentScrollDepthMax,
      cpu_count: cpuCount,
      gpu_details: gpu,
      display_resolution,
      display_dpr,
      display_dpi
    };
  };
  useEffect(() => {
    let isVisitSent = false;
    window.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "hidden") {
        if (isVisitSent) {
          return;
        }
        const visit = constructVisit();
        sendVisit(visit);
        if (!isSpa) {
          isVisitSent = true;
        }
        startTime = getTimeStamps();
      }
    });
    window.addEventListener("beforeunload", () => {
      if (isVisitSent) {
        return;
      }
      const visit = constructVisit();
      sendVisit(visit);
      if (!isSpa) {
        isVisitSent = true;
      }
    });
    let navigation = window.navigation;
    if (!navigation) {
      debugConsole("Polyfill enabled for navigation", "NUCLEUS_TRACKING", "info");
      navigation = document;
      let historyState = location.href;
      const navigateSuccessEvent = new CustomEvent("navigatesuccess");
      const observeHistoryState = () => {
        if (historyState != window.history.state) {
          historyState = history.state;
          document.dispatchEvent(navigateSuccessEvent);
        }
        delay(300).then(() => {
          window.requestAnimationFrame(observeHistoryState);
        });
      };
      observeHistoryState();
    }
    navigation.addEventListener("navigatesuccess", (e) => {
      const newUrl = e?.target?.currentEntry?.url || e?.target?.URL;
      if (currentUrl === newUrl) {
        return;
      }
      const visit = constructVisit(currentUrl);
      currentUrl = newUrl;
      sendVisit(visit);
      isVisitSent = true;
      startTime = getTimeStamps();
      setTimeout(() => {
        isVisitSent = false;
      }, 100);
    });
    window.addEventListener("scroll", () => getScrollDepth());
    return () => {
      isVisitSent = false;
    };
  }, []);
  return /* @__PURE__ */ React.createElement(React.Fragment, null);
};
export const init = async () => {
  if (document.querySelector(`#${target}`)) {
    debugConsole(`target ${target} is already populated. Early return.`, "NUCLEUS_TRACKING_INIT", "warn");
    return;
  }
  document.body.insertAdjacentHTML("beforeend", `<div id="${target}"></div>`);
  const container = document.getElementById(target);
  const root = createRoot(container);
  let visitorId = getStorage(STORAGE_VISITOR_KEY)?.visitorId || null;
  try {
    if (!visitorId) {
      const fingerprint = await Fingerprint();
      visitorId = fingerprint?.visitorId || null;
      visitorId && setStorage(STORAGE_VISITOR_KEY, {visitorId}, VISITOR_TTL);
    }
  } catch (error) {
    console.error("Could not get visitorId");
  }
  root.render(/* @__PURE__ */ React.createElement(Tracking, {
    visitorId
  }));
};
export const unload = async () => {
  try {
    document.querySelector(`#${target}`)?.remove();
  } catch (error) {
    console.error("Could not remove nucleus", error);
  }
};
