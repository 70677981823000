export const sendBeacon = async ({ url, body }) => {
	try {
		if (!window.navigator.sendBeacon) {
			return
		}

		const payload = JSON.stringify(body)
		
		window.navigator?.sendBeacon(url, payload)
	} catch (error) {
		console.error('Could not send beacon')
	}
}